
.MuiOutlinedInput-multiline {
    padding: 0 !important; 
} 

.MuiFilledInput-multiline {
    padding: 0 !important;
}
.MuiSelect-outlined.MuiSelect-outlined {
    padding: 12px 10px !important;
}

.MuiFilledInput-input {
    padding: 12px 10px !important;
}

.MuiOutlinedInput-input {
    padding: 12px 10px !important;
}

input {
    color: black !important;
}
textarea {
    color: black !important;
}

.MuiSelect-select.MuiSelect-select {
    color:black !important
}

.React-confirm-alert-cust-overlay {
    position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1400;
  background: rgba(255, 255, 255, 0.9);
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
  opacity: 0;
  -webkit-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  -moz-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  -o-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
}